<template style="background-color: #EAEAEA">
    <div>
        <div class="container py-5 my-0 my-sm-5 px-4">
            <div>
                <h2 style="color: #707070">{{ $t('future_ai_summit_title').toUpperCase() }}</h2>
                <h6 style="color: #797979">{{ $t('ticket_message') }}</h6>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row align-items-center mb-0 pb-5">
                        <div class="col-12 text-center">
                            <ValidationObserver ref="form">
                                <b-card class="shadow-lg rounded">
                                    <h5 class="text-left mb-2">{{ $t('please_select_a_ticket_type').toUpper() }}</h5>
                                    <ValidationProvider name="ticket_event_id" rules="required"
                                                        v-slot="{ valid, errors }">
                                        <b-form-group v-model="ticket_event_id">
                                            <div class="row">
                                                <div
                                                    v-for="ticket in tickets"
                                                    :key="ticket.id"
                                                    class="col-md-3 mb-2"
                                                >
                                                    <div
                                                        class="ticket-box p-2 border rounded shadow-sm w-100 cursor-pointer"
                                                        :class="{ 'bg-primary': ticket_event_id === ticket.id }"
                                                        @click="selectTicket(ticket.id)"
                                                    >
                                                        <div class="icon-container mb-3">
                                                            <i v-if="ticket.type === 'personal'"
                                                               class="ri-user-line"></i>
                                                            <i v-if="ticket.type === 'group'" class="ri-team-line"></i>
                                                            <i v-if="ticket.type === 'student'"
                                                               class="ri-graduation-cap-line"></i>
                                                            <i v-if="ticket.type === 'organisation'"
                                                               class="ri-user-follow-line"></i>
                                                        </div>
                                                        <h5 class="font-weight-bold">
                                                            <span v-html="formatRadioLabel(ticket)"></span>
                                                        </h5>
                                                        <p v-if="ticket.type === 'group'" class="font-weight-bold" style="font-size: 13px">{{ $t("ticket_count_group_message") }}</p>
                                                        <p v-if="ticket.type === 'personal'">{{ '(' + $t("personal_ticket_info") + ')' }}</p>
                                                        <p v-if="ticket.type === 'group'">{{ '(' + $t("personal_ticket_info") + ')' }}</p>
                                                        <p v-if="ticket.type === 'student'">{{ '(' + $t("personal_ticket_info") + ')' }}</p>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <b-button style="background-color: #f653fb" class="float-right" @click="next">
                                        {{ $t("next") }}
                                    </b-button>
                                </b-card>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TicketEventApplicationService from "@/services/TicketEventApplicationService";
import {localeChanged} from "vee-validate";

export default {
    metaInfo() {
        return {
            title: this.$t("purchase_ticket"),
        };
    },
    data() {
        return {
            tickets: [],
            ticket_event_id: null,
        };
    },
    mounted() {
        this.get();
    },
    created() {
        const lang = this.$route.params.lang || 'tr';
        this.changeLocale(lang);
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
            this.$store.commit('setLocale', locale);
            localeChanged();
        },
        get() {
            TicketEventApplicationService.getTicketEventTypesByCode('future_ai_summit')
                .then((response) => {
                    this.tickets = response.data.data;
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        formatRadioLabel(item) {
            return item.type == 'personal' || item.type == 'group' ? (this.$i18n.locale == 'tr' ? `${item.type_name}<br>(${this.$t("certified")})<br>${item.price} TL` : `${item.type_name_en}<br>(${this.$t("certified")})<br>${item.price} TL`)
                : (this.$i18n.locale == 'tr' ? (item.type == 'student' ? `${item.type_name}<br>(${this.$t("certified")})<br>${this.$t("free")}` : `${item.type_name}<br>(${this.$t("noncertified")})<br>${this.$t("free")}`) : (item.type == 'student' ? `${item.type_name_en}<br>(${this.$t("certified")})<br>${this.$t("free")}` : `${item.type_name_en}<br>(${this.$t("noncertified")})<br>${this.$t("free")}`))
        },
        selectTicket(ticketId) {
            this.ticket_event_id = ticketId;
        },
        async next() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.$store.dispatch('ticketSystem/deleteData');
                const selectedTicket = this.tickets.filter(ticket => ticket.id === this.ticket_event_id);
                this.$store.dispatch("ticketSystem/initData", selectedTicket[0]);
                this.$router.push('/tickets/' + this.ticket_event_id);
            } else {
                return this.$toast.error(this.$t('ticket') + ' ' + this.$t('not_selected'));
            }
        }
    }
}
</script>

<style scoped>
.ticket-box {
    transition: all 0.3s ease;
    cursor: pointer;
    height: 100%;
}

.ticket-box:hover {
    background-color: #f0f0f0;
}

.ticket-box i {
    font-size: 50px;
    color: #707070;
    display: block;
    margin-bottom: 10px;
}

.ticket-box.bg-primary {
    background-color: white !important;
    color: #707070;
}

.ticket-box.bg-primary i {
    color: #f653fb !important;
}

.ticket-box.bg-primary .font-weight-bold {
    color: #f653fb !important;
}

.ticket-box .font-weight-bold {
    font-size: 16px;
}

.icon-container {
    text-align: center;
}
</style>

